@import '../../dx-styles.scss';
@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.theme-light {
	.header-component {
		background-color: rgba(249, 250, 253, .8) !important;
	}

	.header-toolbar {
		background-color: rgba(192, 192, 231, 0.335) !important;
	}

	.dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
		color: $base-text-primary-color !important;
	}

	.user-button.authorization,
	.menu-button .dx-button {
		background-color: $primary-1;
		color: $base-text-primary-color;

		:hover {
			background-color: $primary-2;
			color: $base-text-primary-color;
		}
	}

	.logo-text {
		color: $base-text-primary-color !important;
	}

	.user-name {
		color: $base-text-primary-color;
	}

	.dx-toolbar-after .dx-button {
		background-color: $primary-1 !important;

		i {
			color: $base-text-primary-color !important;
		}
	}
	
}

.theme-blue {
	.header-component {
		background-color: rgb(63, 81, 181);
	}

	.header-toolbar {
		background-color: rgb(63, 81, 181);
	}

	.logo-text {
		color: $primary-1 !important;
	}

	.dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
		color: $base-text-primary-dark-color !important;
	}

	.user-button.authorization,
	.menu-button .dx-button {
		background-color: $primary-4;
		color: $base-text-primary-dark-color;

		:hover {
			background-color: $primary-5;
			color: $base-text-primary-dark-color;
		}
	}

	.dx-toolbar-after .dx-button {
		background-color: $primary-4;

		i {
			color: $base-text-primary-dark-color !important;

		}
	}



	
}

.theme-green-teal {
	.header-toolbar {
		background-color: rgba(0, 150, 136, 1);
	}

	.logo-text {
		color: $primary-1 !important;
	}

	.dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
		color: $secondary-9 !important;
	}

	.user-button.authorization,
	.menu-button .dx-button {
		background-color: $secondary-1;
		color: $secondary-9;

		:hover {
			background-color: $secondary-2;
			color: $secondary-9;
		}
	}

	.dx-toolbar-after .dx-button {
		background-color: $secondary-1 !important;

		i {
			color: $secondary-9 !important;
		}
	}

	.user-name {
		color: $primary-1;
	}

	
}

.header-component {
	flex: 0 0 auto;
	z-index: 1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.header-logo img {
	height: 35px;
	background-size: cover;
}

.logo-text {
	color: $base-text-primary-color !important;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	margin-top: 3px;
}

.dx-item-content .dx-toolbar-item-content {
	color: $base-text-primary-dark-color;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 40px;

	.screen-x-small & {
		padding: 0 20px;
	}
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
	width: $side-panel-min-width;
	text-align: center;
	padding: 0;
}

.header-title .dx-item-content {
	padding: 0;
	margin: 0;
}

.dx-theme-generic {
	.dx-toolbar {
		padding: 10px 0;
	}

	.user-button>.dx-button-content {
		padding: 3px;
	}
}

.user-name {
	font-size: 1rem !important;
	color: $primary-1;
	margin: 0 9px;
}



#icon-user-name {
	display: none;
}

.dx-toolbar-button .dx-toolbar-menu-container .dx-button-content {
	height: 0px !important;
	background-color: $primary-4 !important;
}

.dx-scrollview-content .dx-item-content.dx-toolbar-item-content .user-name {
	color: $base-text-primary-color !important;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 0;
	margin: 0;
	font-size: 14px !important;
	flex-direction: row;

	i {
		font-size: 10px;
		margin-left: 0;
		padding-left: 0;
		margin-top: 2px;
		margin-bottom: 2px;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.dx-scrollview-content .dx-item-content.dx-toolbar-item-content #icon-user-name {
	display: inherit;
}

.logo-bank-box {
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo-bank-img {
		width: 6.563rem;
		height: 0.875rem;
	}

	.logo-text {
		padding-left: 3px;
		font-size: 0.7rem;
	}
}

.header-environment-button.dx-button .dx-button-content {
	padding: 0 !important; 
    position: unset !important; 
}

.header-environment-button {
	max-width: 36px;
}

