@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.environment-menu {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 15px 8px;
	text-align: center;
	background-color: #fff;

	.close-menu-container {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.close-menu {
		background: none;
		border: none;
		cursor: pointer;
	}

	div {
		.dx-dropdowneditor-icon::before {
			color: #fff;
		}

		.dx-dropdowneditor-icon::after {
			color: #fff;
		}

		.dx-buttongroup {
			outline: 1px solid #fff;
			outline-offset: 0;
			border-radius: 15px;
		}

		.dx-texteditor.dx-state-active::before,
		.dx-texteditor.dx-state-focused::before {
			border-bottom: 2px solid #fff;
		}

		.dx-texteditor.dx-state-focused .dx-texteditor-label {
			color: #fff;
		}
	}

	p {
		text-align: justify;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.dx-selectbox {
		text-align: justify;
	}

	.dx-buttongroup-item.dx-button-mode-outlined.dx-button {
		border-radius: 15px;
		width: 50%;
		border-color: $base-text-primary-dark-color;
	}

	.dx-button-mode-contained.dx-buttongroup-first-item.dx-button,
	.dx-button-mode-outlined.dx-buttongroup-first-item.dx-button {
		border: none;
	}

	.dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
	.dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
		border: none;
	}

	.dx-button-mode-outlined.dx-state-selected {
		background-color: $base-text-primary-dark-color;
		border-color: rgba(28, 36, 81, 1);
		color: rgba(28, 36, 81, 1);
	}
}

.dx-theme-material-typography .dx-swatch-additional.environment-menu {
	background-color: transparent;
}

.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label,
.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-after,
.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-before,
.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label,
.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-after,
.dx-swatch-additional.environment-menu .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-before {
	border-color: #fff;
	color: #fff;
}
