.finnekta-button-primary-action {
	height: 2.5rem;
	width: 14.688rem;
	background-color: $secondary-4 !important;
	color: $secondary-10 !important;
	font-family: 'DINRoundPro', sans-serif !important;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700 !important;
	padding-left: 15px;
	padding-right: 15px;
	box-shadow: none;

	.mdi {
		font-size: 0.6rem;
		color: $base-text-primary-button !important;
		margin-right: 10px;
	}

	&:hover {
		background-color: $secondary-5 !important;
		box-shadow: none;
	}

	.dx-button-content{
		.dx-button-text{
			font-weight: 700 !important;
		}
	}
}

.finnekta-button-secondary-action {
	height: 2.5rem;
	width: 219px;
	background-color: $base-bg !important;
	color: $primary-9 !important;
	border: 1px solid $secondary-10 !important;
	font-family: 'DINRoundPro', sans-serif !important;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700 !important;
	padding-left: 15px;
	padding-right: 15px;
	box-shadow: none;

	.mdi {
		font-size: 0.6rem;
		color: $base-text-primary-button !important;
		margin-right: 10px;
	}

	&:hover {
		background-color: $base-bg-hover !important;
		box-shadow: none;
	}

	.dx-button-content{
		.dx-button-text{
			font-weight: 700 !important;
		}
	}
}
