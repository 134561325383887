@import '../../themes/generated/variables.base.scss';

.create-account-form {
	.policy-info {
		margin: 10px 0;
		color: rgba($base-text-color, alpha($base-text-color) * 0.7);
		font-size: 14px;
		font-style: normal;

		a {
			color: rgba($base-text-color, alpha($base-text-color) * 0.7);
		}
	}

	.login-link {
		color: $base-accent;
		font-size: 16px;
		text-align: center;
	}
}
