.dx-data-row.dx-state-hover > td {
	background-color: $primary-1 !important;
}

.dx-datagrid-header-panel {
	padding: 0 !important;
}

.finnekta-textbox-filter {
	height: 2.6rem;
	width: 17rem !important;
}

finnekta-textbox-filter::placeholder {
	height: 2.6rem;
	width: 17rem !important;
	font-size: 1rem !important;
}

.finnkekta-button-filter {
	width: 10rem;
	height: 2.6rem;
	color: $primary-9 !important;
	border-width: 1px !important;
	border-color: $primary-9 !important;
	border-radius: 0.375rem;
	border-style: solid;
	margin-left: 0;
	margin-left: 1rem;

	i {
		color: $primary-9 !important;
	}
}

.finnekta-datagrid-button-add-item {
	height: 2.5rem;
	background-color: $secondary-4 !important;
	color: $secondary-10 !important;
	font-family: 'DINRoundPro', sans-serif !important;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	box-shadow: none;

	.mdi {
		font-size: 0.6rem;
		color: $secondary-10;
		margin-right: 10px;
	}

	&:hover {
		background-color: $secondary-5;
		box-shadow: none;
	}

	&.dx-state-disabled {
		background-color: $base-border-color !important;
		color: $base-text-secondary-color !important;
		border-color: $base-border-color !important;
		cursor: not-allowed;
		opacity: 0.5 !important;

		.mdi {
			color: $base-text-secondary-color !important;
		}
	}
}

.finnekta-button-action {
	border: none;

	.mdi {
		font-size: 0.6rem;
		color: $secondary-10;
		margin-right: 0.1rem;
	}

	&.dx-state-disabled {
		color: $base-text-secondary-color !important;
		cursor: not-allowed;
		opacity: 0.5 !important;

		.mdi {
			color: $base-text-secondary-color !important;
		}
	}
}

.finnekta-datagrid-button-delete-action {
	border: none;
	.mdi {
		font-size: 0.6rem;
		color: $base-error;
		margin-right: 0.1rem;
	}

	&.dx-state-disabled {
		background-color: $base-bg !important;
		color: $base-text-secondary-color !important;
		cursor: not-allowed;
		opacity: 0.5 !important;

		.mdi {
			color: $base-text-secondary-color !important;
		}
	}
}

// Estilos para los títulos de las grillas
.dx-datagrid .dx-header-row .dx-datagrid-text-content {
	color: $primary-9 !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

// cuidado con esta clase, reduce el espacio de las filas de las grillas, lo hacen muy grueso los botones de acciones
.dx-last-data-cell[role='gridcell'] {
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
}

.dx-datagrid-headers td[role='columnheader'] > div {
	color: $primary-9 !important;
	font-weight: 600;
}

.dx-revert-button {
	display: none !important;
}

.dx-cell-modified {
	background-color: transparent !important;
}
