@import '../../themes/generated/variables.base.scss';
@import '../../themes/variables.finnekta.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';

.login-form {
	.link {
		text-align: center;
		font-size: 16px;
		font-style: normal;

		a {
			text-decoration: none;
		}
	}

	.form-text {
		margin: 10px 0;
		color: rgba($base-text-color, alpha($base-text-color) * 0.7);
	}

	.dx-widget .dx-button-default {
		background-color: $secondary-5 !important;
		color: $secondary-10;
	}

	.dx-button-has-icon {
		box-shadow: none !important;
	}

	.dx-button-text {
		font-weight: 600 !important;
		font-size: 1.1rem;
	}

	// eliminar el * del requiered field
	.dx-label span:after {
		display: none;
	}

	.dx-editor-outlined.dx-texteditor-label-outside {
		margin-top: 4px;
		margin-bottom: 8px;
	}

	.login-item-buttom {
		margin-top: 0px;

		.dx-button-mode-contained {
			box-shadow: none;
		}

		.dx-button-mode-contained.dx-state-hover {
			box-shadow: none;
			background-color: $secondary-6 !important;
		}
	}

	.dx-item.dx-box-item {
		margin-top: -8px;
		margin-bottom: -8px;
	}

	// "negrita" para los labels del login
	.textbox-login-label .dx-label span {
		font-weight: 600;
	}

	.item-forgot-password {
		margin-top: 8px;
		margin-bottom: 8px;
		height: 10px;
	}
}
