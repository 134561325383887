@import '../../themes/generated/variables.base.scss';

.footer {
	display: block;
	color: rgba($base-text-color, alpha($base-text-color) * 0.7);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 6px;
	padding-bottom: 6px;

	.footer-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		direction: column;

		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
