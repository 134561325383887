@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta';

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #1c245172 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.semipolar-spinner {
    position: relative;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.semipolar-spinner .ring {
    position: absolute;
    border-radius: 50%;
    border: calc(65px * 0.05) solid transparent;
    border-top-color: $base-text-primary-dark-color;
    border-left-color: $base-text-primary-dark-color;
    animation: semipolar-spinner-animation 2s infinite;
}

.semipolar-spinner .ring:nth-child(1) {
    width: 65px;
    height: 65px;
    animation-delay: calc(2000ms * 0.1 * 4);
}

.semipolar-spinner .ring:nth-child(2) {
    width: 52px;
    height: 52px;
    animation-delay: calc(2000ms * 0.1 * 3);
}

.semipolar-spinner .ring:nth-child(3) {
    width: 39px;
    height: 39px;
    animation-delay: calc(2000ms * 0.1 * 2);
}

.semipolar-spinner .ring:nth-child(4) {
    width: 26px;
    height: 26px;
    animation-delay: calc(2000ms * 0.1 * 1);
}

.semipolar-spinner .ring:nth-child(5) {
    width: 13px;
    height: 13px;
    animation-delay: calc(2000ms * 0.1 * 0);
}

@keyframes semipolar-spinner-animation {
    50% {
        transform: rotate(360deg) scale(0.7);
    }
}

.spinner-text {
    color: $base-text-primary-dark-color !important;
    font-size: 1.1rem;
    font-weight: 700;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 5px;
}