@import './themes/generated/variables.base.scss';
@import './themes/variables.finnekta.scss';
@import './themes/variables.finnekta.primary.palette.scss';
@import './themes/variables.finnekta.secondary.palette.scss';
@import './override-devtreme-layout.scss';
@import './assets/scss/finnekta.scss';

html {
	font-size: 16px;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'DINRoundPro', 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 50px;
}

.theme-light.app-login {
	background-image: url('/assets/img/background-blue.svg') !important;
}

.theme-blue.app-login {
	background-image: url('/assets/img/background-blue.svg') !important;
}

.theme-green-teal.app-login {
	background-image: url('/assets/img/background-green-teal.svg') !important;
}

#home-background-image {
	background-image: url('/assets/img/background-light.svg');
	height: 100svh;
}

.app {
	background-color: darken($base-bg, 5);
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	height: 100%;
	width: 100%;

	.content {
		line-height: 1.5;
		flex-grow: 1;

		h2 {
			font-size: 30px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.container {
		height: 100%;
		flex-direction: column;
		display: flex;
	}

	.layout-body {
		flex: 1;
		min-height: 0;
	}

	.content-block {
		margin-left: 40px;
		margin-right: 40px;
		margin-top: 20px;
	}
}

.side-nav-outer-toolbar .dx-drawer {
	height: calc(100% - 48px);
}

.screen-x-small .content-block {
	margin-left: 20px;
	margin-right: 20px;
}

.responsive-paddings {
	padding: 20px;

	.screen-large & {
		padding: 40px;
	}
}

.dx-card.wide-card {
	border-radius: 0;
	margin-left: 0;
	margin-right: 0;
	border-right: 0;
	border-left: 0;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
	height: 100%;

	&>.dx-scrollview-content {
		display: flex;
		flex-direction: column;
		min-height: 100%;
	}
}

$side-panel-min-width: 60px;

html,
body {
	margin: 0px;
	min-height: 100%;
	height: 100%;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
}

.dx-auto-height {
	height: calc(100vh - 340px);
}

.mdi {
	transform: scale(2);
	padding: 8px;
}

// animacin para girar sobre sí mismo un objeto
@keyframes infinite-rotate {
	from {
		transform: rotate(0deg) scale(2);
	}

	to {
		transform: rotate(360deg) scale(2);
	}
}

.infinite-rotate {
	animation: infinite-rotate 3s linear infinite;
}

.flex-container {
	display: flex;
	justify-content: space-around;
	/* Distribuye el espacio uniformemente */
}

.flex-container .btn-test {
	flex: 1;
	/* Hace que cada botón crezca de igual manera ocupando el espacio disponible */
}

.title-card {
	padding-bottom: 15px;
	margin: 0px;
}

.capital-letter {
	text-transform: capitalize !important;
}

.dx-theme-material .dx-font {
	font-family: "Comic Sans", sans-serif !important;
	/* Cambia esto a la fuente que prefieras */
}

/* Alternativamente, si no estás usando un tema específico de DevExtreme, puedes usar la siguiente regla */
body .dx-font {
	font-family: "Comic Sans", sans-serif !important;
	/* Cambia esto a la fuente que prefieras */
}
