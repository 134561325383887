$primary-1: #eceef8;
$primary-2: #d1d5ed;
$primary-3: #acb4df;
$primary-4: #8691d0;
$primary-5: #6270c2;
$primary-6: #3f51b5;
$primary-7: #36459a;
$primary-8: #2d3a81;
$primary-9: #242e67;
$primary-10: #1c2451;
