.dx-card {
	box-shadow: none;
}

.finnetka-card-text-header {
	color: $primary-9 !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
