@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.environment-menu-drawer {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1000;
	height: 100vh;
	background-color: rgba(28, 36, 81, 1);
}

.environment-drawer-close {
	z-index: 2001;
	width: 0;
	transition: all 0.3s ease-in-out;
}
.environment-drawer-open {
	z-index: 2001;
	width: 250px;
	transition: all 0.3s ease-in-out;
}
