@import '../../themes/generated/variables.base.scss';
@import '../../themes/variables.finnekta.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';
@import '../../themes/variables.finnekta.primary.palette.scss';

.first-login-form {
	.dx-widget .dx-button-default {
		background-color: $secondary-5 !important;
		color: $secondary-10;
	}

	.dx-button-text {
		font-weight: 600 !important;
		font-size: 1.1rem;
	}

	.login-item-buttom {
		margin-top: 8px;

		.dx-button-mode-contained {
			box-shadow: none;
		}

		.dx-button-mode-contained.dx-state-hover {
			box-shadow: none;
			background-color: $secondary-6 !important;
		}
	}

	.title {
		color: $primary-6 !important;
		font-weight: 600;
		font-size: 35px;
		margin-bottom: 8px;
		text-align: center;
		line-height: 1.1;
	}

	.description-message-step-1 {
		color: $base-text-color !important;
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 8px;
		text-align: center;
	}

	.description-message-step-2 {
		color: $primary-6 !important;
		font-weight: 600;
		font-size: 18px;
		text-align: center;
	}

	.steps-box {
		border: 1px solid $base-text-secondary-color;
		padding: 10px;
		border-radius: 4px;
		display: inline-block;
		font-size: 18px;
		width: 100%;
		color: $base-text-color !important;
		margin-bottom: 8px;
		text-align: center;
	}

	.password-instructions {
		text-align: justify;
		font-size: 14px;

		.instructions-list {
			margin-top: 0;
			margin-bottom: 15px;
		}
	}

	// eliminar el * del requiered field
	.dx-label span:after {
		display: none;
	}

	.dx-item.dx-box-item {
		margin-bottom: -20px;
	}

	// "negrita" para los labels del login
	.textbox-login-label .dx-label span {
		font-weight: 600;
	}

	.resend-code-link {
		text-align: center;

		.resend-code-link-text {
			color: $base-text-color;
			font-size: 15px;

			&:hover,
			&:focus,
			&:active {
				color: black;
				text-decoration: underline;
			}
		}
	}
}

.sucess-content {
	.dx-widget .dx-button-default {
		background-color: $secondary-5 !important;
		color: $secondary-10;
	}

	.dx-button-text {
		font-weight: 600 !important;
		font-size: 1.1rem;
	}

	.login-item-buttom {
		margin-top: 8px;

		.dx-button-mode-contained {
			box-shadow: none;
		}

		.dx-button-mode-contained.dx-state-hover {
			box-shadow: none;
			background-color: $secondary-6 !important;
		}
	}
	.box {
		text-align: center;
		margin-bottom: 10px;
		font-size: 16px;

		.mdi {
			font-size: 3rem;
			color: $primary-6 !important;
		}

		.title {
			font-size: 25px;
			font-weight: 600;
			color: $base-text-color !important;
		}
	}
}
