@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.error-page {
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-align: center;
}

.not-complete {
    position: absolute;
    right: 0;
    bottom: 0;
}

.complete {
    position: fixed;
    height: 100vh;
    width: 100vw;  
    flex-direction: column;
    z-index: 1000; 
}

.error-message {
    line-height: 2rem;
    color: $primary-9 !important;

    a {
        font-size: 1.2rem;
        color: $primary-9 !important;
    }
    a:hover {
        cursor: pointer;
    }

    
    h1 {
        font-weight: 600;
        font-size: 15rem;
    }

    h4 {
        font-weight: 500;
    }

}

