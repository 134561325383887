@import '../../../themes/variables.finnekta.primary.palette.scss';
@import '../../../themes/variables.finnekta.secondary.palette.scss';

.btn-copy-json {
	right: 0;
	position: absolute;
	cursor: pointer;
	border: none;
	background: none;
	padding: 0;       
}

._1MGIk {
	color: $primary-6 !important;
}

._vGjyY {
	color: $secondary-8 !important;
}
