@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.custom-container-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 1rem;
}

.custom-action-row {
    display: flex;
    flex-direction: row;
	justify-content: start;
    gap: 1rem; 
}

.popup-box-item{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}
.dx-tabpanel .dx-tabpanel-tab {
	display: flex;
	flex-grow: 1 !important;
}
.dx-tabs .dx-tab {
    justify-content: center !important; 
}