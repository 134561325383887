@import '../../themes/generated/variables.base.scss';
@import '../../themes/variables.finnekta.primary.palette.scss';

.login-card {
	.dx-card {
		background-color: $primary-1;
		width: 480px;
		min-width: 360px;
		margin: auto auto;
		padding: 40px 100px 40px 100px;
		flex-grow: 0;
		border-radius: 10px;

		@media only screen and (max-width: 500px) {
			width: 330px;
		}

		.screen-x-small & {
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			border: 0;
			flex-grow: 1;
		}

		.header {
			margin-bottom: 20px;
			background-image: url('/assets/img/login-header.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 250px;

			.title {
				color: $base-text-color;
				line-height: 28px;
				font-weight: 500;
				font-size: 24px;
			}

			.description {
				color: rgba($base-text-color, alpha($base-text-color) * 0.7);
				line-height: 18px;
			}
		}
	}
}
