@import '../../themes/variables.finnekta.secondary.palette.scss';

.change-password-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.info-block {
	text-align: left;
	background-color: $secondary-2;
	max-width: 500px;
	max-height: 250px;
	padding: 30px 30px;
	border-radius: 8px;
	margin-top: 30px;
	font-weight: 400;
	margin-left: 50px;
	margin-right: 20px;
	display: block;

	@media (max-width: 768px) {
		// Ocultar en pantallas móviles
		display: none;
	  }

	.info-title {
		font-size: 20px;
		font-weight: 600;
	}
}

.form-block {
	margin-top: 20px;
	margin-left: 30px;
	margin-right: 50px;
	.text-box {
		margin-bottom: 40px;
	}
	// eliminar el * del requiered field
	.dx-label span:after {
		display: none;
	}
}
