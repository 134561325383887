$base-accent: #3f51b5;
$base-secondary: #00bcd4;
$base-bg: #f9fafd;
$base-bg-hover: #f2f2f2;
$base-border-color: #bdbdbd;
$base-border-light-color: #f0f0f0;
$base-text-primary-color: #1e1e1e;
$base-text-primary-dark-color: #f0f0f0;
$base-text-secondary-color: #757575;
$base-success: #009688;
$base-success-hover: #008a7c;
$base-error: #e91e63;
$base-error-hover: #dc205e;
$base-warning: #ffc107;
$base-warning-hover: #f4b806;
$base-bg-drawer: rgba(28, 36, 81, 0.635);
$base-bg-drawer-2: rgba(16, 22, 58, 0.835);
$base-text-primary-button: rgba(28, 36, 81, 0.7);
