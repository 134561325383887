@import '../../themes/generated/variables.base.scss';
@import '../../themes/variables.finnekta.primary.palette.scss';

.single-card {
	.dx-card {
		background-color: $primary-1;
		width: 480px;
		min-width: 360px;
		margin: auto auto;
		padding: 40px 80px 40px 80px;
		flex-grow: 0;
		border-radius: 10px;

		.screen-x-small & {
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			border: 0;
			flex-grow: 1;
		}

		.header {
			margin-bottom: 10px;

			.title {
				color: $primary-6;
				line-height: 1.2;
				font-weight: 600;
				font-size: 35px;
				text-align: center;
				margin-bottom: 5px;
				white-space: pre-line;
				
			}

			.description {
				color: $base-text-color;
				font-weight: 600;
				font-size: 18px;
				text-align: center;

			}
		}
	}
}
