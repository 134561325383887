@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.operations-grid-header {
	display: flex;
	align-items: center;
	gap: 10px;

	.title {
		color: $primary-9 !important;
		margin: 0;
	}

	.button {
		background: none;
		border: none;
		text-decoration: underline;
		cursor: pointer;
		padding: 0;
		box-shadow: none;
	}
}

.filter-container {
	margin-bottom: 30px;

	p {
		font-weight: bold;
		margin-bottom: 8px;
	}

	.filter-group {
		display: flex;
		flex-wrap: wrap; 
		justify-content: space-between;

		.filter-item {
			flex: 1 1 200px; 
			margin: 10px;
		}
		
		.filter-item-button {
			flex: 1 1 200px;
			margin-top: 32px;
	
			.dx-button {
				width: 100%;
				height: 48px;
				padding: 7px;
			}
		}
	}
}

