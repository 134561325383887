@import '../../dx-styles.scss';
@import '../../themes/generated/variables.additional.scss';
@import '../../themes/variables.finnekta.scss';
@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';

.theme-light .dx-treeview {
		background-color: $base-bg !important;
		color: $base-text-primary-color !important;
}

.theme-blue .dx-treeview {
	background-color: rgba(28, 36, 81, 0.635);
	color: $base-text-primary-dark-color;
}

.theme-green-teal .dx-treeview {
	background-color: rgba(0, 150, 136, 0.635);
	color: $base-text-primary-dark-color;
}

.side-navigation-menu {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: 100%;
	width: 250px !important;

	.menu-container {
		min-height: 100%;
		display: flex;
		flex: 1;

		.dx-treeview {
			// ## Long text positioning
			white-space: nowrap;
			// ##

			// ## Icon width customization
			.dx-treeview-item {
				padding-left: 0;
				flex-direction: row-reverse;
				.dx-icon {
					width: $side-panel-min-width !important;
					margin: 0 !important;
				}
			}

			.dx-treeview-toggle-item-visibility {
				right: 10px;
				left: auto;
			}

			.dx-rtl .dx-treeview-toggle-item-visibility {
				left: 10px;
				right: auto;
			}
			// ##

			// ## Item levels customization
			.dx-treeview-node {
				&[aria-level='1'] {
					font-weight: 600;
					border-bottom: 1px solid $base-border-color;
				}

				&[aria-level='2'] .dx-treeview-item-content {
					font-weight: normal;
					padding: 0 $side-panel-min-width;
				}
				padding: 0 0 !important;
			}
			// ##
			 // ## Selected & Focuced items customization
			.dx-treeview-node-container {
				.dx-treeview-node {
					&.dx-state-selected:not(.dx-state-focused)
						> .dx-treeview-item {
						background-color: $secondary-8;
					}

					&.dx-state-selected > .dx-treeview-item * {
						color: $base-text-primary-dark-color;
					}

					&:not(.dx-state-focused)
						> .dx-treeview-item.dx-state-hover {
						background-color: $secondary-7;
					}
				}
			}
			// ##
		}

		.dx-theme-generic .dx-treeview {
			.dx-treeview-node-container
				.dx-treeview-node.dx-state-selected.dx-state-focused
				> .dx-treeview-item
				* {
				color: inherit;
			}
		}
		// ##
	}
}

.dx-drawer-overlap.pre-init-blink-fix {
	@import '../../utils/patches.scss';
	@include menu-pre-init-patch;
}
