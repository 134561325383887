$secondary-1: #e6f8fb;
$secondary-2: #b0eaf2;
$secondary-3: #8ae0eb;
$secondary-4: #54d2e2;
$secondary-5: #33c9dd;
$secondary-6: #00bcd4;
$secondary-7: #00abc1;
$secondary-8: #008597;
$secondary-9: #006775;
$secondary-10: #004f59;
