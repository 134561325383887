// mueve la barra de filtro del footer de la grilla a la cabecera
.dx-datagrid-filter-top .dx-datagrid .dx-datagrid-filter-panel {
	order: -2;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

// cambiar los botones predefinidos de OK y Cancel de orden, primero Cancel y luego OK
.dx-popup-bottom .dx-toolbar-after {
	display: flex !important;
	gap: 6px;
	flex-direction: row-reverse;
}

// colores de los toast
.dx-toast-error {
	background-color: $base-error;
	color: $base-text-primary-dark-color;
}

.dx-toast-info {
	background-color: $base-bg;
	color: $base-text-primary-color;
}

.dx-toast-warning {
	background-color: $base-warning;
	color: $base-text-primary-color;
}

.dx-toast-success {
	background-color: $base-success;
	color: $base-text-primary-dark-color;
}

.btnTestWarning {
	background-color: $base-warning !important;
	border-color: $base-warning !important;
	color: $base-text-primary-color !important;
	:hover {
		background-color: $base-warning-hover !important;
	}

	i {
		color: $base-text-primary-color !important;

	}
}

.btnTestSuccess {
	background-color: $base-success !important;
	color: $base-text-primary-color !important;
	:hover {
		background-color: $base-success-hover !important;
	}

	i {
		color: $base-text-primary-color !important;

	}
}

.btnTestError {
	background-color: $base-error !important;
	color: $base-bg !important;
	:hover {
		background-color: $base-error-hover !important;
	}

	i {
		color: $base-bg !important;

	}
}

.custom-toast {
	display: flex;
	/* Enable flexbox for layout */
	align-items: center;
	/* Align content vertically */
	flex-direction: row;
	width: 100%;
}

.custom-toast-message {
	flex: 1;
	/* Allow message text to grow */
	margin: 0;
	/* Remove default margin */
}

.custom-toast-message p {
	margin: 0;
	/* Remove default margin */
}

.custom-toast-button {
	/* Push button to the right */
	cursor: pointer;
	/* Set cursor to pointer on hover */
	color: white;

	.icon-dark {
		color: #383838;
	}

	i {
		font-size: 18px;
		/* Adjust icon size as needed */
		color: white;
		/* Gray icon color */
		transition: color 0.2s ease-in-out;
		/* Smooth transition on hover */
	}
}

// sobreescribir el tamaño de los iconos del drawer que usan iconos de mdi en vez de devextreme
.dx-icon.mdi {
	font-size: 9px !important;
}

.dx-treeview-search {
	background-color: transparent !important;
	height: 40px;

	:focus {
		background-color: $base-bg;
		color: $base-text-primary-color;
	}
}

// remover los bordes del treeview del menu
.dx-treeview-root-node {
	border-bottom: none !important;
}

.btn-green-teal {
	background-color: rgba(0, 150, 136, 1) !important;
}