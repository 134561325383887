.breadcrumb {
	color: $primary-9 !important;
	font-size: 1.4rem !important;
	font-weight: 500 !important;

	.actual {
		color: $primary-9 !important;
	}

	.mdi {
		font-size: 0.9rem;
	}

	a {
		text-decoration: none;
		color: $primary-5 !important;
	}
}

.breadcrumb.actual {
	color: $primary-9 !important;
    font-weight: 500 !important;
}
