@import '../../themes/variables.finnekta.primary.palette.scss';
@import '../../themes/variables.finnekta.secondary.palette.scss';
@import '../../themes/variables.finnekta.scss';

.accordion-title {
	color: $primary-6 !important;
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-left: 0.5rem;
}

.button-container {
	padding-top: 1.5rem;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 1rem;
}

.text-header-title {
	color: $base-text-secondary-color !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.tab-text-header {
	color: $base-text-primary-color !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 0.65rem;
	font-style: normal;
	font-weight: 500;
	margin-left: 2rem;
	margin-bottom: 0;
	padding-bottom: 0;
}

.popup-text-message {
	color: $base-text-primary-color !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 0.80rem;
	font-style: normal;
	font-weight: 700;
}

.permission-settings {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	color: $base-text-primary-color !important;
	font-family: 'DINRoundPro', sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.permission-accordion-content {
	width: 100%;
	border-radius: 0.25rem;
	padding-left: 0.5rem;

	.dx-accordion-item-title {
		color: $base-text-primary-color !important;
		font-size: 1.25rem;
		font-weight: 500;
	}
}

.dx-accordion-item {
	box-shadow: none;
	border-style: none;
	border: none;
}

.permission-accordion-content .dx-accordion-item.dx-accordion-item-opened .dx-accordion-item-title,
.permission-accordion-content .dx-accordion-item-opened {
	background-color: $primary-1 !important;
}

.warning-card {
	background-color: $base-warning !important;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 5px;
	border-radius: 4px;
	gap: 0.5rem;

	i {
		padding-left: 0.5rem;
		justify-self: center;
		align-self: center;
		color: $base-text-primary-color !important;
	}
}

.warning-text-box {
	display: flex;
	color: $base-text-primary-color !important;
	font-size: 1rem;
	font-weight: 400;
	line-height: normal;
}

.box-content {
	width: 600px;
	padding-top: 1rem;
	display: flex;
	gap: 1rem;
	justify-content: start;
	align-items: center;

	.api-key-box {
		height: 36px;
		width: 65%;
	}
}

.dx-tabpanel {
	width: 100% !important;
	padding: 1rem;
}

.dx-tabpanel-tabs .dx-tabs.dx-tabs-vertical {
	border-right: 0 !important;
}

.header-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding-bottom: 0.9rem;

	.header-actions-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 0.8rem;
	}
}

.link-text-header {
	color: $base-text-primary-color !important;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	text-decoration-line: underline;
	cursor: pointer;
}

.header-actions-box .dx-popup .dx-popup-content {
	width: 185px;
	height: 72px;
	color: $primary-1 !important;
	background-color: $primary-6 !important;
	border-radius: 8px;
}

.info-box {
	text-align: start;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 1rem;
	padding: 0.5rem;
}

.popup-box {
	display: flex;
	justify-content: end;
	align-items: center;
	padding-top: 1.5rem;
}

.dx-tabs .dx-tab {
	justify-content: end !important;
}